import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import { sessionsFilterData,sessionsUiData } from "./sessions";
import { passwordReducer } from "./password";

export default combineReducers({
  routing,
  sessionsFilterData,
  sessionsUiData,
  passwordReducer
});