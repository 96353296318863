// @flow

import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import LoadingBlock from './LoadingBlock'

type Props = {
  isOpen: boolean,
  title: string,
  text: string,
  style: Object
};

class ModalLoader extends React.Component<Props> {
  render() {
    const { isOpen, title, text, style } = this.props

    return (
      <Modal
        isOpen={isOpen}
        style={style}>
        <ModalBody>
          <LoadingBlock className="heading1 larger m-b-12" />
          <h2 className="heading2">{title}</h2>
          <div dangerouslySetInnerHTML={{__html: text}} />
        </ModalBody>
      </Modal>
    )
  }
}

export default ModalLoader
