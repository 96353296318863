import React from 'react'
import {connect} from 'react-redux'
import { withRouter, Redirect } from "react-router";
import { verifyProfile } from "../actions/sessions"
import AppStoreLogo from "../images/img-appstore.png";
import GooglePlayLogo from "../images/img-gplay.png";

class Verify extends React.Component{
    constructor(props) {
      super(props)

      this.state = {
        failed: false
      }
    }

    componentDidMount(){
      const { specialKey, email, verifyProfile } = this.props
      verifyProfile(specialKey, email)
      .then(()=> {
      
        if(this.props.parent.get('is_verify')) {
          this.props.history.push("/verify-finish")
        } else {
          this.setState({failed: true})
        }
      })
    }
    
    render(){
      const { failed } = this.state
      return(
        failed ? 
          <div className='finish-verify'>
            <section className="error">
            <div className="error-body">
                <div className="error-content">
                    <div className="text">
                        <p className="p-header">Failed verify the email!</p>
                        <p className="p-body">Please contact the Administrator</p>
                    </div>
                    <div className="store-container">
                        <a href="https://itunes.apple.com/id/app/astronaut-always-be-interviewing/id1098124247?mt=8" target="_blank" rel="noopener noreferrer"><img src={AppStoreLogo} /></a>
                        <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer"><img src={GooglePlayLogo} /></a>
                    </div>
                </div>
            </div>
            </section>
        </div> 
        : ''
      )
    }
}

const mapStateToProps = (state, ownProps) => {
    // const search = ownProps.location.search
    // const token = queryString.parse(search).token
    const email = ownProps.match.params.email
    const token = ownProps.match.params.token
  
    return {
      specialKey: token,
      email: email,
      parent: state.sessionsUiData
    };
  };

const mapDispatchToProps = (dispatch) => {
  return {
    verifyProfile: (token, email) => dispatch(verifyProfile(token, email))
  }
};
 
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Verify)
);