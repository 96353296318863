import _axios from '../dependencies/_axios'

export function sessionsFetchInitialRequestCandidateApi(jobId, generatedCode){
    return _axios({
      method: 'post',
      url: '/api/web/Mmvp/jobs/'+jobId+'/candidateRegister',   
      data: {
        jobId: jobId,
        generatedCode:generatedCode
      }
    })
}

export function sessionsFetchInitialPreviewCandidateApi(jobId,candidateId){
    return _axios({
        method:'get',
        url:'/api/web/Mmvp/jobs/'+jobId+'/candidate/'+candidateId
    })
}

export function sessionsFetchInitialPreviewCandidateApi2(profileUrl) {
  return _axios({
    method:'get',
    url:'/api/profile/profile_detail/' + profileUrl
  })
}

export function verifyProfileApi(token, email) {
  return _axios({
    method:'post',
    url:'/api/profile/verify_profile',
    data: { token: token, email: email }
  })
}

export function verifyProfileFinishApi(token, email) {
  return _axios({
    method:'post',
    url:'/api/profile/finish_verify_profile_new_email',
    data: { token: token, email: email }
  })
}

export function verifyNewEmailApi(token, email) {
  return _axios({
    method:'post',
    url:'/api/profile/verify_profile_new_email/'+email+'/token/'+token
  })
}


export function playVideoApi(videoId) {
  return _axios({
    method:'post',
    url:'/api/profile/video/video_play',
    data: { videoId: videoId }
  })
}

export function reloadDataApi(profileUrl) {
  return _axios({
    method:'post',
    url:'/api/profile/web/reload_profile_data',
    data: { profileUrl: profileUrl }
  })
}