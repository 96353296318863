import _axios from "../dependencies/_axios";
import Promise from 'bluebird'
export function sessionsFetchInitialRequestCandidate(
  jobId,
  generatedCode
) {
  return {
    type: "SESSIONS_FETCH_INITIAL_REQUEST_CANDIDATE",
    jobId,
    generatedCode
  };
}

export function sessionsFetchInitialPreviewCandidate(
  jobId,
  candidateId,
  profileUrl
) {
  return {
    type: "SESSIONS_FETCH_INITIAL_PREVIEW_CANDIDATE",
    jobId,
    candidateId,
    profileUrl
  };
}

export function verifyProfileSuccess(
  token,
  email,
  resolve,
) {
  return {
    type: "SESSIONS_VERIFY_PROFILE",
    token,
    email,
    resolve,
  }
}

export function verifyProfile(token, email) {
  return (dispatch)=> {
    return new Promise((resolve, reject)=>{
      dispatch(verifyProfileSuccess(token, email, resolve))
    })
  }
}

export function verifyFinishProfile(token, email) {
  return (dispatch)=> {
    return new Promise((resolve, reject)=>{
      dispatch(verifyFinishProfileSuccess(token, email, resolve))
    })
  }
}

export function verifyFinishProfileSuccess(
  token,
  email,
  resolve
) {
  return {
    type: "SESSIONS_FINISH_VERIFY_PROFILE",
    token,
    email,
    resolve
  }
}

export function verifyToNewEmail(
  token,
  email
) {
  return {
    type: "SESSIONS_VERIFY_NEW_EMAIL",
    token,
    email
  }
}

export function playVideo(
  videoId
) {
  return {
    type: "SESSIONS_PLAY_VIDEO",
    videoId
  }
}

export function reloadData(profileUrl) {
  return { type: "SESSIONS_RELOAD_DATA", profileUrl }
}