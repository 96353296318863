import { List, fromJS, Map } from "immutable";

const initFilterData = fromJS({
  fullname: "",
  email: "",
  phone: "",
  questions:[],
  videos:[],
  candidate:[],
  job:[],
  company:[],
  profile: [],
  error: false
});


const initUiData = fromJS({
  finished_or_failed_data: {},
  modal_loader_data: {},
  is_verify: false
});

export function sessionsFilterData(state = initFilterData, action) {
  switch (action.type) {
    case "SESSIONS_ADD_FILTER_QUESTIONS_SUCCESS":
      return state.set("questions", List(action.questions));
    case "SESSIONS_ADD_CANDIDATE_SUCCESS":
      return state.set("candidate", Map(action.candidate));
    case "SESSIONS_ADD_JOB_SUCCESS":
      return state.set("job", Map(action.job));
     case "SESSIONS_ADD_COMPANY_SUCCESS":
      return state.set("company", Map(action.company));
    case "SESSIONS_ADD_COUNT_QUESTIONS_SUCCESS":
      return state.set("count_questions", action.count_questions);
    case "SESSIONS_ADD_COUNT_VIDEOS_QUESTION_SUCCESS":
      return state.set("count_videos_question", action.count_videos_question);
    case "SESSIONS_CLEAR_FILTER_DATA_SUCCESS":
      return fromJS({
        questions: [],
        candidate: [],
        count_questions: "",
        count_videos_question: "",
      });
    case "SESSIONS_ADD_FILTER_VIDEOS":
      return state.set("videos", action.videos)
    case "SESSIONS_ADD_PROFILE_SUCCESS":
      return state.set("profile", action.profile)
    case "SESSIONS_FAILED":
      console.log(action.error)
      return state.set("error", action.error)
    default:
      return state;
  }
}

export function sessionsUiData(state = initUiData, action) {
  switch (action.type) {
    case "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS":
      return state.set("modal_loader_data", fromJS(action.modal_loader_data));
    case "SESSIONS_CLEAR_UI_DATA_SUCCESS":
      return fromJS({
        finished_or_failed_data: {},
        modal_loader_data: {},
        is_verify: false
      });
    case "SESSIONS_VERIFY_PROFILE_SUCCESS":
      return state.set("is_verify", action.is_verify)
    case "SESSIONS_FINISH_VERIFY_PROFILE_SUCCESS":
      return state.set("is_verify", action.is_verify)
    default:
      return state;
  }
}
