import React, { Fragment } from 'react'
import {connect} from 'react-redux'
import { withRouter } from "react-router";
import bindAll from "lodash/bindAll";
import initializers from "../dependencies/initializers"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import blockedChrome from '../images/blocked-chrome.gif'
import thumbexample from '../images/star4hire-thumbnail-video.jpg'
import { sessionsFetchInitialPreviewCandidate, playVideo, reloadData } from "../actions/sessions";
import image33 from "../images/loader.gif";
import logoSkala from "../images/img/logo-skala.png";
import astrntLogo from "../images/img/logo-astro-black.svg";
import astrntLogoWhite from "../images/logo-white-big.png";
import profpic from "../images/person.png";
import questionCloud from "../images/question-cloud.png";
import eyePurple from "../images/eye-purple.png";
import shareBtn from "../images/share-button.png";
import vidLong from "../images/6897.mp4";
import $ from 'jquery';
import ModalShare from './ModalShare';
import {Helmet} from 'react-helmet';

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
	if (t < 1) return c/2*t*t + b;
	t--;
	return -c/2 * (t*(t-2) - 1) + b;
};

class ProfileVideoPreviewNew extends React.Component{

    constructor(props) {
      super(props);

      this.state = {
        isCopied:false,
        currentVideoIndex: 0,
        videos: []
      }

      bindAll(this, [
        "handleCopy",
        "handleModalShare",
        "prevSlide",
        "nextSlide",
        "play",
        "scrollTo"
      ]);
 
    }

    componentDidMount(){
      const{
        sessionsFetchInitialPreviewCandidate,
        reloadData,
        jobId,
        candidateId,
        profileUrl
      } = this.props
 
      if(profileUrl) {
        reloadData(profileUrl)
      } else {
        sessionsFetchInitialPreviewCandidate(jobId, candidateId, profileUrl)
      }
    }

    componentDidUpdate() {
      const { sessionsFilterData } = this.props

      console.log('didupdateeeee')
      console.log(sessionsFilterData.get('error'))
      if(sessionsFilterData.get('error')) {
        this.props.history.push('/');
      }

      // set title and icon
      const profile = sessionsFilterData.get('profile')
      if(profile) {
        // document.title = profile.name + " - Astronaut Profile"
        // if(profile.profile_picture) {
        //   let rel = document.querySelector("link[rel*='icon']")
        //   rel.href = profile.profile_picture
        // }
        // document.getElementById('ogTitle').content = profile.name
        // document.getElementById('ogDesc').content = profile.bio
        // document.getElementById('ogUrl').content = profile.profile_url
        // if(profile.profile_picture) {
        //   document.getElementById('ogImage').content = profile.profile_picture
        // }
      }
    }

    handleModalShare(){
      $('#modalShare').modal('show')
    }

    handleCopy(){

     this.setState({isCopied:true})

     setTimeout(() => {
        this.setState({isCopied:false})
     }, 5000);

    }

    prevSlide() {
      const { currentVideoIndex } = this.state
      const { sessionsFilterData } = this.props
      const videos = sessionsFilterData.get('videos')

      if(videos && videos.length > 2) {
        // last index
        const lastIndex = videos.length - 1
        
        // start over
        const resetIndex = currentVideoIndex === 0
        const index = resetIndex ? lastIndex : currentVideoIndex - 1

        this.setState({currentVideoIndex: index})
      }
    }

    nextSlide() {
      const { currentVideoIndex } = this.state
      const { sessionsFilterData } = this.props
      const videos = sessionsFilterData.get('videos')

      if(videos && videos.length > 2) {
        // last index
        const lastIndex = videos.length - 1
        
        // start over
        const resetIndex = currentVideoIndex === lastIndex
        const index = resetIndex ? 0 : currentVideoIndex + 1

        this.setState({currentVideoIndex: index})
      }
    }

    play(e, video, profileName, profilePicture) {
      const { jobId, candidateId, profileUrl, playVideo } = this.props
      const backUrl = profileUrl ? ("/" + profileUrl) : "/" + (jobId + "/candidate/" + candidateId)
      playVideo(video.video_id)
      this.props.history.push({
        pathname: "/video-full", 
        state:{
          name: profileName,
          profile_picture: profilePicture,
          video_url: video.video_url,
          title: video.question_title,
          views: video.views,
          backUrl: backUrl
        } 
      })
    }

    scrollTo(isRight) {
      const element = document.getElementById('apVideo')
      
    }
    
    render(){
    
    const {sessionsFilterData,jobId,candidateId} = this.props
    const { isCopied, currentVideoIndex } = this.state
    // const htmlsVideo = sessionsFilterData.get('questions').toJS()
    const candidateInfo = sessionsFilterData.get('candidate').toJS()
    // const companyInfo = sessionsFilterData.get('company').toJS()
    const videos = sessionsFilterData.get('videos')
    const profile = sessionsFilterData.get('profile')

    if(sessionsFilterData.get('error') || !profile) {
      return '';
    }

    var profilePicture = null;
    var profileName = profile ? profile.name : candidateInfo.fullname
    var profileBio = profile ? profile.bio : '';
    if(profile && profile.profile_picture) {
      profilePicture = profile.profile_picture
    }
    
    var host;
    var texthost;
    if (process.env.REACT_APP_API_HOST == "https://app.astrnt.co") {
       host = 'https://profile.astrnt.co'
       texthost = 'profile.astrnt.co'
    }else if(process.env.REACT_APP_API_HOST == "https://beta.astrnt.co"){
        host = 'https://profile-beta.astrnt.co'
        texthost = 'profile-beta.astrnt.co'
    }else{
        host = 'http://localhost:3000'
        texthost = 'localhost:3000'
    }

    // var profileUrl = profile.profile_url ? (texthost+ "/" + profile.profile_url) : (texthost + "/" + jobId + "/candidate/" + candidateId)
    var profileUrl = profile.profile_url;
    
    // video list
    const index = currentVideoIndex
    const size = videos.length
    // set shown videos
    let shownVideos = videos.slice(index, index + 3)
    // let shownVideos = videos
    if(shownVideos.length < 3 && size > 2) {
      shownVideos = shownVideos.concat(videos.slice(0, 3 - shownVideos.length))
    }
   
        return (
          <Fragment>
           <div className="div-container-new">
            <header>
              <Helmet>
                <title>{profileName}</title>
                <meta property="og:title" content={profileName}/>
                {/* <meta property="og:description" content={profileBio}/> */}
                <meta property="og:url" content={profileUrl}/>
                <meta property="og:image" content={profilePicture}/>
                <meta name="description" content={profileBio} />
                {/* <meta id="meta-description" name="description" content="Some description." />
                <meta id="og-title" property="og:title" content={profileName} />
                <meta id="og-image" property="og:image" content={profilePicture} /> */}
              </Helmet>
              <section>
                  <ul className="the-job">
                      <li className="li-profile">
                          <a onClick={this.handleModalShare}><img className="btn-share-purple" src={shareBtn} /></a>
                          <div className="div-profile"><span>{profileName}</span>{profilePicture ? <img src={profilePicture} className="profpic" /> : <img src={profpic} className="profpic default-profpic" />}</div>
                      </li>
                      <li className="li-desc">
                          {profileBio}
                      </li>
                  </ul>
              </section>
          </header>
          <div>
            {
              videos.length == 0 ?
              <div></div>
              :
              <section className="video-list" id='apVideo'>
                <ul className="relative ul-video">
                    {
                      shownVideos.map( (v,i) => 
                        <li key={v.video_id}>
                          <div className="div-total-view"><img src={eyePurple} />{v.views}</div>
                          <video controlsList="nodownload" onClick={(e)=>this.play(e, v, profileName, profilePicture)} poster={v.thumbnail_url}>
                              <source src={v.video_url} type="video/mp4"/>
                          </video>
                          <div className="div-question-video"><img src={questionCloud} /></div>
                          <button className="btn-play-profile" onClick={(e)=>this.play(e, v, profileName, profilePicture)}><i className="fa fa-play"></i></button>
                        </li>
                      )
                    }
                <button className="btn-nav-prev-2" onClick={this.prevSlide}><i className="fa fa-chevron-left"></i></button>
                <button className="btn-nav-next-2" onClick={this.nextSlide}><i className="fa fa-chevron-right"></i></button>
                {/* <button className="btn-nav-prev-2" onClick={(e)=>this.scrollTo(false)}><i className="fa fa-chevron-left"></i></button>
                  <button className="btn-nav-next-2" onClick={(e)=>this.scrollTo(true)}><i className="fa fa-chevron-right"></i></button> */}
                </ul>
              </section>
            }
          </div>
          </div>
          <ModalShare profileUrl={profileUrl} />
          </Fragment>         
        )  
    }
}


const mapStateToProps = (state, ownProps) => {
  let jobId = ownProps.match.params.jobId;
  let candidateId = ownProps.match.params.candidateId;
  let profileUrl = ownProps.match.params.profileUrl;

  // alert(ownProps.match.params.jobId)
  // alert(ownProps.match.params.candidateId)

  return {
    jobId:  jobId,
    candidateId:  candidateId,
    profileUrl: profileUrl,
    sessionsFilterData: state.sessionsFilterData,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    sessionsFetchInitialPreviewCandidate: (jobId, candidateId, profileUrl) =>
      dispatch(
        sessionsFetchInitialPreviewCandidate(jobId, candidateId, profileUrl)
      ),
    playVideo: (videoId) => dispatch(playVideo(videoId)),
    reloadData: (profileUrl) => dispatch(reloadData(profileUrl))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProfileVideoPreviewNew)
);
