import React, { Fragment } from 'react'
import {connect} from 'react-redux'
import { withRouter } from "react-router";
import bindAll from "lodash/bindAll";
import ModalLoader from "../../lib/elements/ModalLoader";
import { Map } from "immutable";

class RootIndex extends React.Component{

    constructor(props) {
    super(props);
    // this.state = {
    //   isConnected: true,
    //   mainWindow: true
    // };
    bindAll(this, [
      "decideMainLayout",
    ]);
  }

 

    decideMainLayout(){
      const {
          jobId,
          children,
          sessionsUiData
      }=this.props  

        const modalLoaderData: Object = sessionsUiData
        .get("modal_loader_data")
        .toJS();


        // console.log(sessionsUiData.toJS())
        
         return (
          <Fragment>
            {children}
            <ModalLoader {...modalLoaderData} />
          </Fragment>
        );

        //testcase
        // if (jobId == 1){
        //     return <div>Job id ente gak ada di database (ceritanya) </div>;
        // }else{
        //     return <Fragment>{children}</Fragment>
        // }
    }

    render(){
        return (
            <div>
            {this.decideMainLayout()}
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
  return {
    jobId:  ownProps.location.pathname.split("/")[1],
    sessionsUiData: state.sessionsUiData,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(RootIndex)
);
