import React, { Fragment } from 'react'
import {connect} from 'react-redux'
import { withRouter } from "react-router";
import bindAll from "lodash/bindAll";
import randomstring from "randomstring";
import { sessionsFetchInitialRequestCandidate } from "../actions/sessions";

class SessionCreate extends React.Component{
    constructor(props) {
    super(props);
   
  }

   componentDidMount(){
     const {
          jobId,
          sessionsFetchInitialRequestCandidate
      }=this.props 
      const randomName = randomstring.generate({
        length: 12,
        charset: 'alphabetic'
      });
      sessionsFetchInitialRequestCandidate(jobId,randomName)
  }

  
  

    render(){
        return (
            <div>
            
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
  return {
    jobId:  ownProps.location.pathname.split("/")[2]
  };
};



const mapDispatchToProps = dispatch => {
  return {
    sessionsFetchInitialRequestCandidate: (jobId, generatedCode) =>
      dispatch(
        sessionsFetchInitialRequestCandidate(jobId, generatedCode)
      )
  };
};


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SessionCreate)
);