import { fromJS } from "immutable";

const init = fromJS({
    name: '',
    password: '',
    password2: ''
})


export function passwordReducer(state = init, action) {
    switch(action.type) {
        case 'PROFILE_NAME':
            return state.set('name', action.name);
        case 'REDUCERS_POST_RESET':
            return state.set('isSuccess', action.isSuccess)
        default:
            return state;
    }
}