
import React from 'react'
import { Link } from 'react-router-dom' 
import FinishResetLogo from "../images/finish-reset.png";
import AppStoreLogo from "../images/img-appstore.png";
import GooglePlayLogo from "../images/img-gplay.png";


class FinishVerify extends React.Component{
    // componentDidMount(){
    //     $('#root').addClass('error-page')
    // }
    // componentWillUnmount(){
    //      $('$root').removeClass('error-page')
    // }
    render(){
        return(
        <div className='finish-verify'>
            <section className="error">
            <div className="error-body">
                <div className="error-content">
                    <div className="text">
                        <p className="p-header">Successfully verified!</p>
                        <p className="p-body">Congratulations! You have successfully verified the email address.</p>
                        <p className="p-body">To continue on mobile now, install our highly-rated mobile app.</p>
                    </div>
                    <div className="store-container">
                        <a href="https://itunes.apple.com/id/app/astronaut-always-be-interviewing/id1098124247?mt=8" target="_blank" rel="noopener noreferrer"><img src={AppStoreLogo} /></a>
                        <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer"><img src={GooglePlayLogo} /></a>
                    </div>
                </div>
            </div>
            </section>
        </div>
        
        )
    }
}
 
export default FinishVerify

