import React, { Fragment } from 'react'
import {connect} from 'react-redux'
import { withRouter, Redirect } from "react-router";
import bindAll from "lodash/bindAll";
import _axios from '../dependencies/_axios';
import $ from 'jquery';
import AstroProfileLogo from "../images/astro-profile-logo.png";
import { getProfileName, resetPassword } from "../actions/password";

class PasswordReset extends React.Component{

    constructor(props) {
      super(props);

      this.state = {
        pass: '',
        pass2: '',
        isPassError: false,
        isPass2Error: false,
        isMobile: false
      }

      bindAll(this, [
        "save",
        'updatePassword',
        'updatePassword2',
        'handleFocusInput'
      ]);
 
    }

    componentDidMount(){
      const { getProfileName, specialKey } = this.props

      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      let isAndroid = /android/i.test(userAgent)
      let isApple    = /ipad|iphone|ipod/i.test(userAgent)

      // alert('astronaut://reset-password/' + specialKey)
      
      if(isAndroid) {
        this.setState({isMobile: true})
        
        if(specialKey) {
          window.location = 'astronaut://reset/' + specialKey
          setTimeout(() => {
            // window.location = 'https://play.google.com/store/apps/details?id=co.astrnt.androidqa'
            this.setState({isMobile: false})
          }, 5000)
        }
      } else if(isApple) {
        this.setState({isMobile: true})
        if(specialKey) {
          window.location = 'astronaut://reset?token=' + specialKey
          // window.location = 'https://profile-beta.astrnt.co/reset/' + specialKey
          setTimeout(() => {
            // window.location = 'https://play.google.com/store/apps/details?id=co.astrnt.androidqa'
            this.setState({isMobile: false})
          }, 5000)
        }
      }

      getProfileName(specialKey)


      // $(".field-wrapper .field-placeholder").on("click", function () {
      //     $(this).closest(".field-wrapper").find("input").focus();
      // });
      // $(".field-wrapper input").on("keyup", function () {
      //     var value = $.trim($(this).val());
      //     if (value) {
      //         $(this).closest(".field-wrapper").addClass("hasValue");
      //     } else {
      //         $(this).closest(".field-wrapper").removeClass("hasValue");
      //     }
      // });
      // $('#placeholder-password').click(function(){
      //   $('#input-password').focus();
      // });
      // $('#span-password').click(function(){
      //   $('#input-password').focus();
      // });
      // $('#placeholder-password2').click(function(){
      //   $('#input-password2').focus();
      // });
      // $('#span-password2').click(function(){
      //   $('#input-password2').focus();
      // });
    }

    handleFocusInput(e, param) {
      if (param == 'password1') {
        this.password1.focus();
      } else {
        this.password2.focus();
      }
    }

    save(e) {
      const { resetPassword, specialKey } = this.props
      const { isPassError, isPass2Error, pass, pass2 } = this.state

      if(isPassError || isPass2Error || pass === '' || pass2 === '') {
        
        return
      }
      e.preventDefault()
      resetPassword(specialKey, pass)
    }

    updatePassword(e) {
      const { pass2, isPass2Error } = this.state
      const value = e.target.value
      this.setState({'pass': value, isPassError: false})
      var pattern = /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/;
      // logic
      if(value.length < 8 || !pattern.test(value)) {
        this.setState({isPassError: true})
      } else {
        if(isPass2Error || pass2 !== '') {
          if(value === pass2) {
            this.setState({isPass2Error: false})
          } else {
            this.setState({isPass2Error: true})
          }
        }
      }
    }

    updatePassword2(e) {
      const { pass } = this.state
      const value = e.target.value
      this.setState({'pass2': value, isPass2Error: false})

      // logic
      if(value !== pass) {
        this.setState({isPass2Error: true})
      }
    }
    
    render() {

      const { profile, specialKey } = this.props
      const { isPassError, isPass2Error, isMobile } = this.state

      if(profile.get('isSuccess')) {
        setTimeout(this.props.history.push("/reset-password-finish"), 2000)
      }

      let hasValue1='';
      let hasValue2='';

      if (this.state.pass.trim() !== '') {
        hasValue1 ='hasValue'
      }

      if (this.state.pass2.trim() !== '') {
        hasValue2 ='hasValue'
      }
   
        return (
         [isMobile?
          '' 
          :
          <div className='reset-pass-page'>
            <section className="pass-container">
              <div className="pass-body">
                  <div className="pass-content">
                      <div className="text">
                          <div className="page-title"><img src={AstroProfileLogo} /><label>Set New Password</label></div>
                          <p>{ profile.get('name') }</p>
                      </div>
                      <div className="form-reset">
                        <form action="#">
                          <div className={`field-wrapper ${isPassError ? 'error' : ''} ${hasValue1}`} onClick={(e)=>this.handleFocusInput(e,'password1')}>
                              <input ref={(input) => { this.password1 = input; }} id="input-password" type="password" name="password" value={this.state.pass} onChange={this.updatePassword} />
                              <div id="placeholder-password" className="field-placeholder"><span id="span-password">New Password</span></div>
                          </div>
                          <div className={`error-message ${isPassError ? '' : 'hide'}`}>Your password must contain at least 8 characters, one number, an uppercase and a lowercase letter.</div>
                          <div className={`field-wrapper ${isPass2Error ? 'error' : ''} ${hasValue2}`} onClick={(e)=>this.handleFocusInput(e,'password2')}>
                              <input ref={(input) => { this.password2 = input; }} id="input-password2" type="password" name="password2" value={this.state.pass2} onChange={this.updatePassword2}/>
                              <div id="placeholder-password2" className="field-placeholder"><span id="span-password2">Retype New Password</span></div>
                          </div>
                          <div className={`error-message ${isPass2Error ? '' : 'hide'}`}>Your password and confirmation password do not match.</div>
                          <div className="form-button">
                              <button type="button" onClick={e => this.save(e)} className="btn-save-password">Save New Password</button>
                          </div>
                        </form>
                      </div>
                  </div>
              </div>
            </section>
        </div>
        ]
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  // const search = ownProps.location.search
  // const token = queryString.parse(search).token
  const token = ownProps.match.params.token

  return {
    specialKey: token,
    profile: state.passwordReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProfileName: (token) => dispatch(getProfileName(token)),
    resetPassword: (token, password) => dispatch(resetPassword(token, password))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PasswordReset)
);
