import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import SessionCreate from './components/sessioncreate';
import RootIndex from './components/root/rootindex';
import PageNotFoundNew from './components/pages/PageNotFoundNew'
import ProfileVideoPreview from './components/profileVideoPreview';
import ProfileVideoPreviewNew from './components/profileVideoPreviewNew';
import ProfileVideoFull from './components/profileVideoFull';
import PasswordReset from './components/passwordReset';
import FinishReset from './components/FinishReset';
import ProfileCodeCheck from './components/ProfileCodeCheck';
import VerifyNewEmail from './components/VerifyNewEmail';
import Verify from './components/Verify';
import VerifyProfileFinish from './components/VerifyProfileFinish';
import FinishVerify from './components/FinishVerify';
import * as serviceWorker from './serviceWorker';
import { Route } from "react-router-dom";
import { Provider } from "react-redux";
import { Switch,Router } from "react-router";
import history from "./dependencies/history";
import configureStore from "./store/configureStore";
import bootstrap from "bootstrap";

// import './styles/sass/public/bootstrap-custom.scss'
// import 'font-awesome/css/font-awesome.css'
// import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
// import 'react-select/dist/react-select.css'
// import 'nprogress/nprogress.css'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'
import "./styles/css/style.css";
import "./styles/css/style-responsive.css";


import './styles/sass/public/style.scss'
import './styles/sass/main.scss'
import './styles/sass/style.scss'
import './styles/sass/style-responsive.scss'


const store = configureStore();
ReactDOM.render(
<Provider store={store}>
   <Router history={history}>
    <RootIndex>
        <Switch>
            <Route exact path="/code/:jobId" component={SessionCreate}/>
            <Route exact path="/profile/:code" component={ProfileCodeCheck}/>
            <Route path="/:jobId/candidate/:candidateId" component={ProfileVideoPreview}/>
            {/* <Route path="/reset/:token" component={PasswordReset}/> */}
            <Route path="/reset/:token" component={PasswordReset}/>
            <Route path="/reset-password/:token" component={PasswordReset}/>
            <Route path="/video-full" component={ProfileVideoFull}/>
            <Route path="/reset-password-finish" component={FinishReset}/>
            <Route path="/verify_profile/:email/token/:token" component={Verify}/>
            <Route path="/verify_profile_finish/:email/token/:token" component={VerifyProfileFinish}/>
            <Route path="/verify-finish" component={FinishVerify}/>
            <Route path="/verify-new-email/:email/token/:token" component={VerifyNewEmail}/>
            <Route path="/:profileUrl" component={ProfileVideoPreviewNew}/>
            <Route component={PageNotFoundNew} />
        </Switch>
    </RootIndex>
   </Router>
</Provider>
, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
