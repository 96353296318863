import React from 'react'
import { Link } from 'react-router-dom'
import profpic from "../images/person.png";
import questionCloud from "../images/question-cloud.png";
import eyeWhite from "../images/eye-white.png";
import vidLong from "../images/6897.mp4";
import bindAll from 'lodash/bindAll';
// import ReactPlayer from "react-player"

class profileVideoFull extends React.Component{
    // componentDidMount(){
    //     $('#root').addClass('error-page')
    // }
    // componentWillUnmount(){
    //      $('$root').removeClass('error-page')
    // }

    constructor(props) {
        super(props)
        bindAll(this, [
          "close", "closeByClick"
        ])
    }

    componentDidMount() {
        document.addEventListener("keydown", this.close, false)
    }

    close(e) {
        if(27 === e.keyCode) {
            const location = this.props.location;
            const backUrl = location.state.backUrl;
            this.props.history.push(backUrl)
        }
    }

    closeByClick(e) {
        const location = this.props.location;
        const backUrl = location.state.backUrl;
        this.props.history.push(backUrl)
    }

    render(){
      const location = this.props.location;
      const v = location.state.video_url
      const name = location.state.name
      const PP = location.state.profile_picture
      const title = location.state.title
      const views = location.state.views

        return(
        <div className='video-full-page'>
            <section className="video-page">
            <div className="video-body p-0">
                <div className="video-content">
                    <div className="div-total-view-full"><img src={eyeWhite} />{views}</div>
                    <div className="div-username-full">
                        <span>{name}</span>
                        {PP ? <img src={PP} /> : <img src={profpic} className="default-profpic" />}
                    </div>
                    <div className="div-close-mobile-full" onClick={this.closeByClick}>
                        <i className="fa fa-times"></i>
                    </div>
                    <video controlsList="nodownload" autoPlay={true} ref="video" onClick={() => {this.refs.video.play()}}>
                        <source src={v} type="video/mp4"/>
                    </video>
                    {/* <ReactPlayer url={v} /> */}
                    <div className="div-question-video-full">
                        <div className="isi">
                            <img src={questionCloud} />
                            <span>{title}</span>
                        </div>
                    </div>
                    {/* <button className="btn-play-profile"><i className="fa fa-play"></i></button> */}
                </div>
            </div>
            <div>
                {/* <button className="btn-nav-prev-2-full"><i className="fa fa-chevron-left"></i></button>
                <button className="btn-nav-next-2-full"><i className="fa fa-chevron-right"></i></button> */}
                <button className="btn-close-vid-full" onClick={this.closeByClick}><i className="fa fa-times"></i></button>
            </div>
            </section>
        </div>
        
        )
    }
}
 
export default profileVideoFull
