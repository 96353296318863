import { Map } from 'immutable'
import UAParser from 'ua-parser-js'



export function setHttpHeaders(params: Object = {}): Object {
    const parser = new UAParser()
    const device = parser.getDevice()
    const os = parser.getOS()
    const browser = parser.getBrowser()
  
    const headers = {
      device: '',
      os: `${os.name} ${os.version}`,
      browser: `${browser.name} ${browser.version}`,
      screenresolution: `${window.screen.availHeight}x${window.screen.availWidth}`
    }
  
    if (Object.keys(params).length) {
      Object.assign(headers, params)
    }
  
    if (device.type) {
      Object
        .assign(
          headers,
          {
            device: `${device.type} ${device.model} ${device.vendor}`
          }
        )
    }
  
    return headers
  }
  