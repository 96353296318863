// @flow

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import bindAll from 'lodash/bindAll'
import { Map } from 'immutable'
import $ from 'jquery'
import userIcon from "../images/user-icon.png";
import iconCopy from "../images/share-copy.png";
import iconFBMS from "../images/share-fb-ms.png";
import iconWA from "../images/share-wa.png";
import iconFB from "../images/share-fb.png";
import iconTwitter from "../images/share-twitter.png";
import iconLinkedin from "../images/share-linkedin.png";
import iconEmail from "../images/share-email.png";

class ModalShare extends React.Component{
  constructor(props) {
    super(props)

    bindAll(this, [
      'handleToggleModal',
      'handlePopUp',
    ])
  }

  handleToggleModal(e) {
    e.preventDefault()

    $('#modalShare').modal('hide')
  }

  handlePopUp(e, value){
    const { profileUrl } =this.props
		var thisPageURL = profileUrl
		var url = ''
		if(value == 'facebook'){
			  url = 'https://www.facebook.com/sharer/sharer.php?&u='+thisPageURL
				// 'http://www.facebook.com/dialog/feed?'+
				//	'app_id='+thisPageURL
				// 			'link='+thisPageURL+
				// 			'message=Join us. Click here: '+thisPageURL
		}
		if(value == 'Whatsapp'){
			  url = 'https://web.whatsapp.com/send?text=Join us. Click here: '+thisPageURL
		}
		if(value == 'linkedin'){
			  url = 'https://www.linkedin.com/sharing/share-offsite/?url='+thisPageURL
		}
		if(value == 'twitter'){
			  url = 'https://twitter.com/intent/tweet?url='+thisPageURL+'&text=Join us. Click here: '
		}
		if(value == 'email'){
			  url ='https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&body=Join us. Click here: '+thisPageURL
    }
    // if(value == 'fbMS') {
    //   // url = 'http://www.facebook.com/dialog/send?' + 'app_id=12334567890' + '&link=' + thisPageURL + '&redirect_url=https://astrnt.co'
    // }
		window.open(url,'popUpWindow','height=600,width=600,left=400,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
	}

  render() {
    const { profileUrl } =this.props

    return (
      <div className="modal" id="modalShare" tabIndex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog">
          <div className="modal-content animated">
            <div className="modal-header p-16">
              <label>Share This Profile</label><i onClick={this.handleToggleModal} className="fa fa-times"></i>
            </div>
            <div className="modal-body p-20">
              <div className="vertical-item--center div-link">
                <div><img className="user-icon" src={userIcon}/></div>
                <div><b>{profileUrl}</b></div>
              </div>
              <div className="p-t-20 p-b-20 div-label">
                <hr className="left"/>Share Profile<hr className="right"/>
              </div>
              <div className="p-0 vertical-item--center div-icon">
                <div className="div-left">
                  <div className="m-b-20"><img className="copy" src={iconCopy} onClick={() => {navigator.clipboard.writeText(window.location.href); alert('Copied!')}}/></div>
                  {/*<div className="p-11 vertical-item--center"><i className="fa fa-envelope" onClick={(e) => this.handlePopUp(e, 'email')}></i></div>*/}
                </div>
                <div className="div-right">
                  <div><img src={iconEmail} onClick={(e) => this.handlePopUp(e, 'email')} /></div>
                  <div><img src={iconWA} onClick={(e) => this.handlePopUp(e, 'Whatsapp')} /></div>
                  <div><img src={iconLinkedin} onClick={(e) => this.handlePopUp(e, 'linkedin')} /></div>
                  <div><img src={iconTwitter} onClick={(e) => this.handlePopUp(e, 'twitter')} /></div>
                  <div><img src={iconFB} onClick={(e) => this.handlePopUp(e, 'facebook')} /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ModalShare
