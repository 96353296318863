import React, { Fragment } from 'react'
import {connect} from 'react-redux'
import { withRouter } from "react-router";
import bindAll from "lodash/bindAll";
import initializers from "../dependencies/initializers"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import blockedChrome from '../images/blocked-chrome.gif'
import thumbexample from '../images/star4hire-thumbnail-video.jpg'
import { sessionsFetchInitialPreviewCandidate } from "../actions/sessions";
import image33 from "../images/loader.gif";
import logoSkala from "../images/img/logo-skala.png";
import astrntLogo from "../images/img/logo-astro-black.svg";
import astrntLogoWhite from "../images/logo-white-big.png";

class ProfileVideoPreview extends React.Component{

    constructor(props) {
      super(props);

      this.state = {
			isCopied:false
		}



    bindAll(this, [
      "handleCopy",
    ]);
 
    }

    componentDidMount(){
      const{
        sessionsFetchInitialPreviewCandidate,
        jobId,
        candidateId
      } = this.props
 
    
      sessionsFetchInitialPreviewCandidate(jobId,candidateId, null)
    }

    

    handleCopy(){

     this.setState({isCopied:true})

     setTimeout(() => {
        this.setState({isCopied:false})
     }, 5000);

    }

    
    render(){
    
    const {sessionsFilterData,jobId,candidateId} = this.props
    const {isCopied} = this.state
    const htmlsVideo = sessionsFilterData.get('questions').toJS()
    const candidateInfo = sessionsFilterData.get('candidate').toJS()
    const companyInfo = sessionsFilterData.get('company').toJS()
    var host;
    var texthost;
    if (process.env.REACT_APP_API_HOST == "https://app.astrnt.co") {
       host = 'https://profile.astrnt.co'
       texthost = window.location.hostname
    }else if(process.env.REACT_APP_API_HOST == "https://beta.astrnt.co"){
        host = 'https://profile-beta.astrnt.co'
        texthost = window.location.hostname
    }else{
        host = 'http://localhost:3000'
        texthost = window.location.hostname
    }

   
        return (
          [
          htmlsVideo.length == 0 && sessionsFilterData.getIn(['company','title']) == undefined ? 
          <div className="wrapper-overlay" id="modalOnBoarding">
                      <div className="overlay-content flex-container align-items-center">
                        <img src={image33} className="img-responsive img-loader" alt="" />
                      </div>
                    </div>
          :

           <Fragment>
           <div className="div-container">
            <header>
              <section className="old-width">
                  {companyInfo.logoUrl == "" ?
                  <Fragment>
                    <div className="desktop-hide"><a href="#" target="_blank"><img className="astrnt-logo-header" src={astrntLogoWhite} /></a></div>
                    <div className="div-logo-com-astrnt mobile-hide"><div><a href="#" target="_blank"><img className="astrnt-logo-header" src={astrntLogoWhite} /></a></div></div>
                  </Fragment>
                  :
                   <a href="#" target="_blank" className="logo-com"><img src={companyInfo.logoUrl} /></a>
                  }
                  <ul className="the-job">
                      <li>
                          {candidateInfo.fullname}
                      </li><li>
                          Email <a href="#" className="the-url">{candidateInfo.email}</a>
                      </li><li>
                          &bull;
                      </li><li>
                          <div className="desktop-hide bg-transparent">Profile URL <a href={'/'+jobId+'/candidate/'+candidateId} className="the-url">{texthost+'/'+jobId+'/candidate/'+candidateId}</a></div>
                          <div className="mobile-hide">Profile URL <span className="span-url-mobile"><a href={'/'+jobId+'/candidate/'+candidateId} className="the-url">{texthost+'/'+jobId+'/candidate/'+candidateId}</a></span></div>
                          <CopyToClipboard onCopy={this.handleCopy} text={texthost+'/'+jobId+'/candidate/'+candidateId}>
                           
                             {isCopied ==true ?   
                             <a href="#" className="the-copy is-on">Copied</a> 
                             :
                              <a href="#" className="the-copy">Copy</a>
                              }
                            
                          </CopyToClipboard>
                      </li>
                  </ul>
              </section>
          </header>
          <div className="bg-body-old">
            <section className="old-width video-list-old">
                <ul>
                    {htmlsVideo.length == 0 && sessionsFilterData.getIn(['company','title']) == undefined  ?
                       <div>
                       </div>
                   :
                     htmlsVideo.map( (val,i) => 
                    <li key={i}>
                        <div className="vid-tit" title={val.title}>{val.title}</div>
                        <video controls controlsList="nodownload" playsinline="1">
                            <source src={val.videoUrl} type="video/mp4"/>
                        </video>
                    </li>
                     )
                    }
                </ul>
            </section>
          </div>
          <footer>
              Powered by <a href="#"><img src={astrntLogo} /></a> All Rights Reserved.
          </footer>
          </div>
          </Fragment>
        ]
         
        )  
    }
}


const mapStateToProps = (state, ownProps) => {
  return {
    jobId:  ownProps.location.pathname.split("/")[1],
    candidateId:  ownProps.location.pathname.split("/")[3],
    sessionsFilterData: state.sessionsFilterData,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    sessionsFetchInitialPreviewCandidate: (jobId, candidateId, profilelUrl) =>
      dispatch(
        sessionsFetchInitialPreviewCandidate(jobId, candidateId, profilelUrl)
      )
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProfileVideoPreview)
);
