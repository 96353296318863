import { all, takeLatest } from "redux-saga/effects";
import {
sessionsFetchInitialRequestCandidate,
sessionsFetchInitialPreviewCandidate,
verifyProfile,
verifyFinishProfile,
verifyToNewEmail,
playVideo,
reloadData
} from "./sessions";
import { getProfile, resetPassword } from "./password";

export default function* root(){
    yield all([
        takeLatest("SESSIONS_FETCH_INITIAL_REQUEST_CANDIDATE", sessionsFetchInitialRequestCandidate),
        takeLatest("SESSIONS_FETCH_INITIAL_PREVIEW_CANDIDATE",sessionsFetchInitialPreviewCandidate),
        takeLatest("SESSIONS_VERIFY_PROFILE",verifyProfile),
        takeLatest("SESSIONS_VERIFY_NEW_EMAIL",verifyToNewEmail),
        takeLatest("SESSIONS_FINISH_VERIFY_PROFILE",verifyFinishProfile),
        takeLatest("SESSIONS_PLAY_VIDEO",playVideo),
        takeLatest("SESSIONS_RELOAD_DATA",reloadData),
        takeLatest("SAGAS_PROFILE_NAME", getProfile),
        takeLatest("SAGAS_RESET_PASSWORD", resetPassword)
    ])
}