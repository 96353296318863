// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import imgGPlay from '../images/img-gplay.png'
import imgAppStore from '../images/img-appstore.png'
import initializers from '../dependencies/initializers'
import _axios from '../dependencies/_axios'
import UAParser from 'ua-parser-js'
import $ from 'jquery'
import { withRouter } from "react-router"
// import Loader from '../../partials/Loader'

type Props = {
	interviewCode: string
};

class ProfileCodeCheck extends React.Component<Props> {
	constructor(props) {
		super(props)

		bindAll(this, [
			'handleCheckInterviewCode'
		])

		this.state = {
			isMobile: false
		}

	}

	handleCheckInterviewCode() {
		const { interviewCode,history } = this.props

		
		const userAgent = navigator.userAgent || navigator.vendor || window.opera

		const parser = new UAParser()
		const isChrome = /chrome/i.test(parser.getBrowser().name)
		const isFirefox = /firefox/i.test(parser.getBrowser().name)
		const isSafari = /safari/i.test(parser.getBrowser().name)	
		
		if (/android/i.test(userAgent)) {
					this.setState({isMobile:true})
					window.location = 'astronaut://astrnt.co/code/' + interviewCode

							setTimeout(() => {
								window.location = 'https://play.google.com/store/apps/details?id=co.astrnt.androidqa'
							}, 5000)
				
		        
		      
		    } else if (/ipad|iphone|ipod/i.test(userAgent) && !window.MSStream) {
					this.setState({isMobile:true})

				
					setTimeout(() => {
						window.location = 'https://itunes.apple.com/id/app/astronaut-always-be-interviewing/id1098124247?mt=8'
					}, 5000)
					window.location = `astronaut://entercode?code=${interviewCode}`

						
		      
		    } 


	}

	componentDidMount() {
		this.handleCheckInterviewCode()
	}

	render() {
		const {isMobile} = this.state
        const showpage= true
		return(
			[
			showpage == false ? 
					null
				:

			<div className="wrapper-maintenance center">
				<h1>Ready to take action? Great!</h1>
				<p>For your convenience, you can answer the questions <br />in the Astronaut mobile app (iOS and Android)</p>
				<div className="inline-content">
					<div className="flex-container">
					<a
						href="https://itunes.apple.com/us/app/astronaut-always-be-interviewing/id1098124247"
						className="m-r-10"
						target="_blank"
						rel="noopener noreferrer">
						<img src={imgAppStore} className="img-responsive" alt="" />
					</a>
					<a
						href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa"
						target="_blank"
						rel="noopener noreferrer">
						<img src={imgGPlay} className="img-responsive" alt="" />
					</a>
					</div>
				</div>
			</div>

			]
			
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		code: ownProps.match.params.code
	}
}

export default withRouter(connect(mapStateToProps, null)(ProfileCodeCheck))
