
import React from 'react'
import { Link } from 'react-router-dom' 
import FinishResetLogo from "../images/finish-reset.png";
import AppStoreLogo from "../images/app-store-logo.png";
import GooglePlayLogo from "../images/google-play-logo.png";
import { verifyToNewEmail } from "../actions/sessions"
import {connect} from 'react-redux';
import { withRouter, Redirect } from "react-router";

class VerifyNewEmail extends React.Component{
    // componentDidMount(){
    //     $('#root').addClass('error-page')
    // }
    // componentWillUnmount(){
    //      $('$root').removeClass('error-page')
    // }

    componentDidMount(){
      const { specialKey, email, verifyToNewEmail } = this.props
      verifyToNewEmail(specialKey, email)
    }
    render(){
        return(
        <div className='verify-new-email'>
            <section className="error">
            <div className="error-body">
                <div className="error-content">
                    <img className="logo-finish" src={FinishResetLogo} />
                    <div className="text">
                        <p className="p-error-page bold">Verify your new email address!</p>
                        <p className="p-error-page">Before you are able to change your email, you have to verify that your new email is exist.</p>
                        <p className="p-error-page last">If you do not find the email in your inbox, please check your spam folder.</p>
                    </div>
                </div>
            </div>
            </section>
        </div>
        
        )
    }
}
 
const mapDispatchToProps = dispatch => {
  return {
    verifyToNewEmail: (token, email) => dispatch(verifyToNewEmail(token, email))
  };
};
const mapStateToProps = (state, ownProps) => {
    // const search = ownProps.location.search
    // const token = queryString.parse(search).token
    const email = ownProps.match.params.email
    const token = ownProps.match.params.token
  
    return {
      specialKey: token,
      email: email,
      parent: state.sessionsUiData
    };
  };
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VerifyNewEmail)
);
