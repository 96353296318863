import _axios from '../dependencies/_axios'

export function getProfileAPI(token) {
    return _axios({
      method: 'post',
      url: '/api/profile/auth/get_reset',
      data: { token: token }
    })
  }
  
  export function resetPasswordAPI(token, password) {
    return _axios({
      method: 'post',
      url: '/api/profile/auth/post_reset',
      data: { token: token, password: password }
    })
  }