import { put, call} from "redux-saga/effects";
import { getProfileAPI, resetPasswordAPI } from "../apis/password";

export function* getProfile({token}: Object)  {

    try {
        let response = yield call(getProfileAPI, token)
        if(response.data.status !== 'error') {
            yield put({
                type: 'PROFILE_NAME', name: response.data.profile.name
            })
        } else {
            yield put({
                type: 'PROFILE_NAME', name: 'error! ' + response.data.message
            })
        }
    } catch(err) {
        yield put({
            type: 'PROFILE_NAME', name: 'error!'
        })
    }
}

export function* resetPassword({token, password}) {

    try {
        let response = yield call(resetPasswordAPI, token, password)
        if(response.data.status !== 'error') {
            yield put({
                type: 'REDUCERS_POST_RESET', isSuccess: true
            })
        } else {
            yield put({
                type: 'PROFILE_NAME', name: 'error! ' + response.data.message
            })
        }
    } catch(e) {
        console.log(e)
    }
}