export function getProfileName(token) {
    return {
        type: 'SAGAS_PROFILE_NAME', token
    }
}

export function resetPassword(token, password) {
    return {
        type: 'SAGAS_RESET_PASSWORD', token, password
    }
}