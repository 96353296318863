import { put, call, all, select, take ,delay} from "redux-saga/effects";
import { Map, List, fromJS } from "immutable";
import UAParser from "ua-parser-js";
import Swal from "sweetalert2";
import {
  sessionsFetchInitialRequestCandidateApi,
  sessionsFetchInitialPreviewCandidateApi,
  sessionsFetchInitialPreviewCandidateApi2,
  verifyProfileApi,
  verifyNewEmailApi,
  verifyProfileFinishApi,
  playVideoApi,
  reloadDataApi
} from "../apis/sessions";
import { resetPassword } from "../actions/password";

export function* sessionsFetchInitialPreviewCandidate({
    jobId,
    candidateId,
    profileUrl
}:Object):Generator<*,*,*>{
    try {

        let response: Object = {};
        let questions = [];
        let candidate = [];
        let count_videos_question = [];
        let job = [];
        let company = [];

        if(profileUrl == null){
            response = yield call(sessionsFetchInitialPreviewCandidateApi, jobId,candidateId)
        } else {
            response = yield call(sessionsFetchInitialPreviewCandidateApi2, profileUrl)
        }
        
        // console.log(response)

        if(response.status >= 200 && response.status < 300){
            if(profileUrl == null) {
                questions = response.data.questions
                candidate = response.data.candidate
                candidate = response.data.candidate
                count_videos_question = response.data.count_videos_question
                job = response.data.job
                company = response.data.company
            }

            let tmpVideos = []
            if(response.data.profile_questions) {
                response.data.profile_questions.map(a=> {
                    tmpVideos.push(a)
                })
            }
            
            yield put({
                type: "SESSIONS_ADD_FILTER_QUESTIONS_SUCCESS",
                questions: questions
                }); 
            
            yield put({
                type: "SESSIONS_ADD_CANDIDATE_SUCCESS",
                candidate: candidate
                }); 

            yield put({
                type: "SESSIONS_ADD_COUNT_VIDEOS_QUESTION_SUCCESS",
                count_videos_question: count_videos_question
                }); 

            yield put({
                type: "SESSIONS_ADD_JOB_SUCCESS",
                job: job
                }); 
                
            yield put({
                type: "SESSIONS_ADD_COMPANY_SUCCESS",
                company: company
                }); 

            yield put({
                 type: "SESSIONS_ADD_FILTER_VIDEOS",
                 videos: tmpVideos
             })

            yield put({
                type: "SESSIONS_ADD_PROFILE_SUCCESS",
                profile: response.data.profile
            })
            // console.log('doneeee')
        }else{
            throw response;
        }


    } catch (error) {
        console.log(error)
        console.log(error.data)
    }
}



export function* sessionsFetchInitialRequestCandidate({
    jobId,
    generatedCode
}: Object): Generator<*,*,*>{

    try{
        let response: Object = {};
           yield put({
                type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
                modal_loader_data: {
                isOpen: true
              
                }
            }); 

        const parser = new UAParser();
        const browserName = parser.getBrowser().name;
        const supported_browser = ['Chrome','Firefox','Opera'];
        if(supported_browser.includes(browserName)){
            response = yield call(sessionsFetchInitialRequestCandidateApi, jobId,generatedCode)
        
            if(response.data.status_code >= 200 && response.data.status_code < 300){
                
           
            window.location.replace(process.env.REACT_APP_QNA_HOST+'/code/'+response.data.interview_code); 
            // yield delay(2000);

            yield put({
                type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
                modal_loader_data: { isOpen: false }
            });
            }else{
                throw response;
            }     
        }else{
            window.location.replace(process.env.REACT_APP_QNA_HOST); 
        }
                  
    }catch(error){
        

        yield put({
            type: "SESSIONS_ADD_UI_MODAL_LOADER_DATA_SUCCESS",
            modal_loader_data: { isOpen: false }
        });   

        if(error.data.status){ 
          Swal.fire({
            type: "error",
            title: "Whoops!",
            text: error.data.message
          });

        }else{
            console.log(error)
            Swal.fire({
            type: "error",
            title: "Whoops!",
            text: "Please Check Your Connection"
          });
        }
        
    }

}

export function* verifyProfile({
    token,
    email,
    resolve,
}: Object): Generator<*,*,*>{

    try {
        let response: Object = {};
        response = yield call(verifyProfileApi, token, email)
        // console.log(response)
        if(response.data.status == "success") {
            yield put({
                type: "SESSIONS_VERIFY_PROFILE_SUCCESS",
                is_verify: true
            });
            yield call(resolve)
        } else {
            yield put({
                type: "SESSIONS_VERIFY_PROFILE_SUCCESS",
                is_verify: false
            });
            yield call(resolve)
        }
    } catch(e){
        console.log(e)
        yield put({
            type: "SESSIONS_VERIFY_PROFILE_SUCCESS",
            is_verify: false
        });
        yield call(resolve)
    }
}

export function* verifyToNewEmail({
    token,
    email
}: Object): Generator<*,*,*>{
    try {
        let response: Object = {};
        response = yield call(verifyNewEmailApi, token, email)
    } catch(e){
        console.log(e)
    }
}

export function* verifyFinishProfile({
    token,
    email,
    resolve
}: Object): Generator<*,*,*>{
    try {
        let response: Object = {};
        response = yield call(verifyProfileFinishApi, token, email)
        // console.log(response)
        if(response.data.status == "success") {
            yield put({
                type: "SESSIONS_FINISH_VERIFY_PROFILE_SUCCESS",
                is_verify: true
            });
            yield call(resolve)
        } else {
            yield put({
                type: "SESSIONS_FINISH_VERIFY_PROFILE_SUCCESS",
                is_verify: false
            });
            yield call(resolve)
        }
    } catch(e){
        console.log(e)
        yield put({
            type: "SESSIONS_FINISH_VERIFY_PROFILE_SUCCESS",
            is_verify: false
        });
        yield call(resolve)
    }
}
export function* playVideo({
    videoId
}: Object): Generator<*,*,*>{

    try {
        let response: Object = {};
        response = yield call(playVideoApi, videoId)
    } catch(e){
        console.log(e)
    }
}

export function* reloadData({profileUrl}: Object): Generator<*,*,*> {
    try {
        let response: Object = {};
        response = yield call(reloadDataApi, profileUrl)

        // console.log(response)

        if(200 == response.status && response.data.status == "success") {
            yield put({
                type: "SESSIONS_ADD_FILTER_VIDEOS",
                videos: response.data.videos
            })

            yield put({
                type: "SESSIONS_ADD_PROFILE_SUCCESS",
                profile: response.data.profile_user
            })
        } else {
            yield put({
                type: "SESSIONS_FAILED",
                error: true
            })
        }
    } catch(e) {
        
        yield put({
            type: "SESSIONS_FAILED",
            error: true
        })
    }
}