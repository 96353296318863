
import React from 'react'
import { Link } from 'react-router-dom' 
import FinishResetLogo from "../images/finish-reset.png";
import AppStoreLogo from "../images/app-store-logo.png";
import GooglePlayLogo from "../images/google-play-logo.png";


class FinishReset extends React.Component{
    // componentDidMount(){
    //     $('#root').addClass('error-page')
    // }
    // componentWillUnmount(){
    //      $('$root').removeClass('error-page')
    // }
    render(){
        return(
        <div className='finish-reset'>
            <section className="error">
            <div className="error-body">
                <div className="error-content">
                    <img className="logo-finish" src={FinishResetLogo} />
                    <div className="text">
                        <p className="p-error-page bold">Your password has been changed successfully!</p>
                        <p className="p-error-page">You can use the new password to login to our mobile apps</p>
                    </div>
                    <div className="store-container">
                        <a href="https://itunes.apple.com/id/app/astronaut-always-be-interviewing/id1098124247?mt=8" target="_blank" rel="noopener noreferrer"><img src={AppStoreLogo} /></a>
                        <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer"><img src={GooglePlayLogo} /></a>
                    </div>
                </div>
            </div>
            </section>
        </div>
        
        )
    }
}
 
export default FinishReset

